import React, { useState, useEffect } from 'react';
import { RiBuilding4Line, RiGroupLine, RiPieChartLine } from 'react-icons/ri';
import './Showcase.css';

const Showcase = () => {
  const [activeMetric, setActiveMetric] = useState(0);
  const metrics = [
    {
      icon: <RiBuilding4Line />,
      value: "1",
      label: "Campus Building",
      description: "Connected and monitored"
    },
    {
      icon: <RiGroupLine />,
      value: "100+",
      label: "Students",
      description: "Making smarter decisions"
    },
    {
      icon: <RiPieChartLine />,
      value: "98.5%",
      label: "Accuracy",
      description: "In occupancy detection"
    }
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveMetric((prev) => (prev + 1) % metrics.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <section className="showcase">
      <div className="showcase-container">
        <div className="showcase-metrics">
          <div className="metrics-grid">
            {metrics.map((metric, index) => (
              <div
                key={index}
                className={`metric-card ${index === activeMetric ? 'active' : ''}`}
              >
                <div className="metric-icon">{metric.icon}</div>
                <div className="metric-content">
                  <h3 className="metric-value">{metric.value}</h3>
                  <p className="metric-label">{metric.label}</p>
                  <span className="metric-description">{metric.description}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="showcase-content">
          <h2 className="showcase-title">
            Making an Impact
            <br />
            <span className="gradient-text">Across Campus</span>
          </h2>
          <p className="showcase-description">
            Join the growing number of institutions transforming their campus experience with real-time occupancy insights
          </p>
          <div className="showcase-cta">
            <button className="demo-button">
              Request Demo
            </button>
            <div className="success-rate">
              <div className="success-icon">★</div>
              <div className="success-text">
                <span>100% satisfaction rate</span>
                <small>Based on customer feedback</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Showcase;
