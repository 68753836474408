import React from 'react';
import { useNavigate } from 'react-router-dom';
import { RiArrowRightLine } from 'react-icons/ri';
import './WhyUs.css';

const WhyUs = () => {
  const navigate = useNavigate();

  const handleGetStarted = () => {
    window.scrollTo(0, 0);
    navigate('/signin');
  };

  return (
    <section className="why-us">
      <div className="why-us-container">
        <div className="why-us-content">
          <h2 className="why-us-title">
            Why Choose
            <br />
            <span className="gradient-text">CampusLens?</span>
          </h2>
          <p className="why-us-description">
            Advanced technology meeting industry standards at a fraction of the cost
          </p>
          <div className="why-us-stats">
            <div className="stat">
              <span className="stat-number">4x</span>
              <span className="stat-label">More Cost-Effective</span>
              <span className="stat-description">Than industry average pricing</span>
            </div>
            <div className="stat-divider"></div>
            <div className="stat">
              <span className="stat-number">100%</span>
              <span className="stat-label">Industry Standards</span>
              <span className="stat-description">Matching performance metrics</span>
            </div>
            <div className="stat-divider"></div>
            <div className="stat">
              <span className="stat-number">24/7</span>
              <span className="stat-label">Reliability</span>
              <span className="stat-description">Continuous monitoring & support</span>
            </div>
          </div>
          <div className="cta-section">
            <h3 className="cta-title">Ready to get started?</h3>
            <button className="cta-button" onClick={handleGetStarted}>
              Get Started
              <RiArrowRightLine />
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyUs;
