import React from 'react';
import { RiBarChartLine, RiTimeLine, RiBrainFill } from 'react-icons/ri';
import './Features.css';

const Features = () => {
  const features = [
      {
      icon: <RiBrainFill className="feature-icon" />,
      title: "AI-Powered Detection",
      description: "Advanced computer vision technology for accurate and reliable occupancy tracking."
    },
    {
      icon: <RiBarChartLine className="feature-icon" />,
      title: "Real-Time Analytics",
      description: "Get instant insights into facility occupancy levels across your campus."
    },
    {
      icon: <RiTimeLine className="feature-icon" />,
      title: "Predictive Patterns",
      description: "Make informed decisions with AI-powered occupancy predictions and trends."
    }
  ];

  return (
    <section className="features">
      <div className="features-container">
        <h2 className="features-title">
          Technology That Powers Us
        </h2>
        <p className="features-subtitle">
          Our cutting-edge AI solutions deliver accurate, real-time facility occupancy data
        </p>
        <div className="features-grid">
          {features.map((feature, index) => (
            <div key={index} className="feature-card">
              <div className="feature-icon-wrapper">
                {feature.icon}
              </div>
              <h3 className="feature-title">{feature.title}</h3>
              <p className="feature-description">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Features;