import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './SignUp.css';
import { useAuth } from '../../services/auth.service';

const SignUp = () => {
  const {signup} = useAuth();

  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: '',
  });
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Sign up:', formData);
    signup({email: formData.email, password: formData.password})
  };

  return (
    <div className="signup-container">
      <div className="signup-card">
        <h1>Create Account</h1>
        <p className="signup-subtitle">Join CampusLens to get started</p>
        <form onSubmit={handleSubmit} className="signup-form">
          <div className="signup-form-group">
            <label>Email</label>
            <input
              type="email"
              name="email"
              placeholder="Enter your email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          {/* <div className="signup-form-group">
            <label>Organization</label>
            <select
              name="organization"
              value={formData.organization}
              onChange={handleChange}
              required
            >
              <option value="">Select your institution</option>
              <option value="uor">University of Rochester</option>
              <option value="rit">Rochester Institute of Technology</option>
              <option value="naz">Cornell University</option>
              <option value="sjf">Syracuse University</option>
              <option value="other">Other</option>
            </select>
          </div>
          <div className="signup-form-group">
            <label>Role</label>
            <select
              name="role"
              value={formData.role}
              onChange={handleChange}
              required
            >
              <option value="">Select your role</option>
              <option value="user">User</option>
              <option value="manager">University Manager</option>
              <option value="admin">Administrator</option>
            </select>
          </div> */}
          <div className="signup-form-group">
            <label>Password</label>
            <input
              type="password"
              name="password"
              placeholder="Create a password"
              value={formData.password}
              onChange={handleChange}
              required
            />
          </div>
          <div className="signup-form-group">
            <label>Confirm Password</label>
            <input
              type="password"
              name="confirmPassword"
              placeholder="Confirm your password"
              value={formData.confirmPassword}
              onChange={handleChange}
              required
            />
          </div>
          <div className="terms-checkbox">
            <label>
              <input type="checkbox" required />
              <span>I agree to the <Link to="/terms">Terms of Service</Link> and <Link to="/privacy">Privacy Policy</Link></span>
            </label>
          </div>
          <button type="submit" className="signup-button">
            Create Account
          </button>
        </form>
        <p className="signup-redirect">
          Already have an account? <Link to="/signin">Sign In</Link>
        </p>
      </div>
    </div>
  );
};

export default SignUp;
