import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { RiArrowRightLine } from 'react-icons/ri';
import './Hero.css';

const Hero = () => {
  const [currentCard, setCurrentCard] = useState(0);
  const navigate = useNavigate();

  const facilities = [
    { name: 'Rush Rhees Library', current: 75, capacity: 200, status: 'HIGH' },
    { name: 'iZone', current: 45, capacity: 150, status: 'MODERATE' },
    { name: 'Carlson Library', current: 85, capacity: 180, status: 'HIGH' },
    { name: 'Goergen Athletic Center', current: 25, capacity: 100, status: 'LOW' },
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentCard((prev) => (prev + 1) % facilities.length);
    }, 3000);
    return () => clearInterval(timer);
  }, []);

  const handleGetStarted = () => {
    window.scrollTo(0, 0);
    navigate('/signin');
  };

  return (
    <section className="hero">
      <div className="hero-content">
        <h1 className="hero-title">
          Smart Campus,
          <br />
          <span className="gradient-text">Smarter Decisions</span>
        </h1>
        <p className="hero-subtitle">
          Real-time occupancy tracking powered by AI for a more efficient campus experience.
        </p>
        <button className="cta-button" onClick={handleGetStarted}>
          Get Started
          <RiArrowRightLine className="arrow-icon" />
        </button>
      </div>
      <div className="hero-visual">
        <div className="stats-container">
          <div className="stats-card">
            <div className="stat-item">
              <div className="facility-header">
                <h3>{facilities[currentCard].name}</h3>
                <div className="live-indicator">
                  <span className="pulse"></span>
                  LIVE
                </div>
              </div>
              <div className="occupancy-bar">
                <div
                  className="bar-fill"
                  style={{
                    width: `${(facilities[currentCard].current / facilities[currentCard].capacity) * 100}%`
                  }}
                ></div>
              </div>
              <div className="occupancy-info">
                <span className="count">
                  {facilities[currentCard].current}/{facilities[currentCard].capacity}
                </span>
                <span className={`status ${facilities[currentCard].status.toLowerCase()}`}>
                  {facilities[currentCard].status}
                </span>
              </div>
            </div>
            <div className="card-nav">
              {facilities.map((_, index) => (
                <div
                  key={index}
                  className={`nav-dot ${index === currentCard ? 'active' : ''}`}
                  onClick={() => setCurrentCard(index)}
                ></div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
