import React, { useState } from 'react';
import './Contact.css';
import { RiTimeLine, RiBuildingLine, RiMoneyDollarCircleLine } from 'react-icons/ri';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    organization: '',
    facilities: '',
    timeline: '',
    budget: '',
    requirements: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
  };

  return (
    <div className="contact">
      <section className="contact-hero">
        <div className="contact-container">
          <h1 className="contact-title">
            Let's Transform Your <span className="gradient-text">Campus</span>
          </h1>
          <p className="contact-description">
            Ready to revolutionize your campus management? Tell us about your vision, and we'll help bring it to life.
          </p>
        </div>
      </section>
      <section className="contact-form-section">
        <div className="contact-container">
          <div className="contact-info">
            <div className="info-item">
              <h3>Headquarters</h3>
              <p>Rochester, NY 14627</p>
            </div>
            <div className="info-item">
              <h3>Email Us</h3>
              <p>campuslens@u.rochester.edu</p>
            </div>
            <div className="info-item">
              <h3>Call Us</h3>
              <p>+1 (314) 488 1305</p>
            </div>
          </div>
          <form className="contact-form" onSubmit={handleSubmit}>
            <div className="form-row">
              <div className="form-group">
                <label>Name</label>
                <input
                  type="text"
                  name="name"
                  placeholder="Your Full Name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label>Email</label>
                <input
                  type="email"
                  name="email"
                  placeholder="Your Email Address"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label>Organization</label>
                <input
                  type="text"
                  name="organization"
                  placeholder="University/Institution Name"
                  value={formData.organization}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label>Number of Facilities</label>
                <select
                  name="facilities"
                  value={formData.facilities}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select number of facilities</option>
                  <option value="1">1 Facility</option>
                  <option value="2">2 Facilities</option>
                  <option value="3">3 Facilities</option>
                  <option value="4">4 Facilities</option>
                  <option value="5">5 Facilities</option>
                  <option value="more">More than 5 Facilities</option>
                </select>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label>Implementation Timeline</label>
                <select
                  name="timeline"
                  value={formData.timeline}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select Timeline</option>
                  <option value="immediate">Immediate</option>
                  <option value="1-3months">1-3 months</option>
                  <option value="3-6months">3-6 months</option>
                  <option value="6+months">6+ months</option>
                </select>
              </div>
              <div className="form-group">
                <label>Budget Range</label>
                <select
                  name="budget"
                  value={formData.budget}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select Budget</option>
                  <option value="0-300">$0 - $300</option>
                  <option value="300-600">$300 - $600</option>
                  <option value="600-900">$600 - $900</option>
                  <option value="900+">$900+</option>
                </select>
              </div>
            </div>
            <div className="form-group">
              <label>Specific Requirements</label>
              <textarea
                name="requirements"
                placeholder="Tell us about your specific needs (e.g., types of facilities, special monitoring requirements)"
                value={formData.requirements}
                onChange={handleChange}
                rows="3"
              ></textarea>
            </div>
            <div className="form-group">
              <label>Additional Information</label>
              <textarea
                name="message"
                placeholder="Any other details you'd like to share?"
                value={formData.message}
                onChange={handleChange}
                rows="4"
              ></textarea>
            </div>
            <button type="submit" className="submit-btn">
              Get Started
            </button>
          </form>
        </div>
      </section>
    </div>
  );
};

export default Contact;
