import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Header from './components/Header/Header';
import Hero from './components/Hero/Hero';
import Features from './components/Features/Features';
import Integration from './components/Integration/Integration';
import Showcase from './components/Showcase/Showcase';
import WhyUs from './components/WhyUs/WhyUs';
import Footer from './components/Footer/Footer';
import About from './components/About/About';
import Contact from "./components/Contact/Contact";
import SignIn from "./components/SignIn/SignIn";
import SignUp from "./components/SignUp/SignUp";
import Dashboard from "./components/Dashboard/Dashboard";
import './App.css';
import { AuthProvider } from './services/auth.service';
import AuthCallback from './components/AuthCallback/AuthCallback';

function App() {
  return (
    <AuthProvider >
      <div className="app">
      <Header />
      <Routes>
        <Route path="/" element={
          <>
            <Hero />
            <Features />
            <Integration />
            <Showcase />
            <WhyUs />
          </>
        } />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path='/auth-success' element={<AuthCallback/>} />
      </Routes>
      <Footer />
    </div>
    </AuthProvider>
  );
}

export default App;