import React, { useState, useEffect } from 'react';
import { Link, redirect, useNavigate } from 'react-router-dom';
import './SignIn.css';
import { useAuth } from '../../services/auth.service';

const SignIn = () => {
  const {login} = useAuth();
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    //console.log('Sign in:', formData);
    const res = await login({
      email: formData.email,
      password: formData.password
    });
    console.log(res);
    if(res && res.access_token){
      navigate("/dashboard");
    }
  };

  const handleGoogleLogin = async() => {
    window.location.href = "/api/auth/google/login"
  }

  return (
    <div className="signin-container">
      <div className="signin-card">
        <h1>Welcome Back</h1>
        <p className="signin-subtitle">Sign in to access your dashboard</p>
        <form onSubmit={handleSubmit} className="signin-form">
          <div className="signin-form-group">
            <label>Email</label>
            <input
              type="email"
              name="email"
              placeholder="Enter your email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="signin-form-group">
            <label>Password</label>
            <input
              type="password"
              name="password"
              placeholder="Enter your password"
              value={formData.password}
              onChange={handleChange}
              required
            />
          </div>
          <div className="signin-options">
            <label className="remember-me">
              <input type="checkbox" /> Remember me
            </label>
            <Link to="/forgot-password" className="forgot-password">
              Forgot Password?
            </Link>
          </div>
          <button type="submit" className="signin-button">
            Sign In
          </button>
        </form>
        <button className='google-signin-button' onClick={handleGoogleLogin}>
            Login with google
        </button>
        <p className="signin-redirect">
          Don't have an account? <Link to="/signup">Sign Up</Link>
        </p>
      </div>
    </div>
  );
};

export default SignIn;
