import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../services/auth.service';

function AuthCallback() {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const {setCurrentUser} = useAuth();
  useEffect(() => {
    // Extract the token from the URL
    const queryParams = new URLSearchParams(window.location.search);
    const access_token = queryParams.get('access_token');
    const tokenType = queryParams.get('token_type');
    
    if (access_token) {
      setCurrentUser({access_token: access_token, tokenType: tokenType});      
      // Redirect to your dashboard or home page
      navigate('/dashboard');
    } else {
      // Handle the case where no token is present
      console.error('No access token found in URL');
      navigate('/login');
    }
    
    setIsLoading(false);
  }, [navigate]);

  // Show a loading state while processing
  return isLoading ? <div>Loading...</div> : null;
}

export default AuthCallback;