import axios from 'axios';
const API_URL = 'https:/campuslens.xyz/api/detections';

const getAllDetections = async()=>{
    try{
        const res = await axios.get(API_URL + "/summary");
        return res.data;
    }catch(err){
        console.log(err);
    }
}


export {getAllDetections};