import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { RiMenuLine, RiCloseLine, RiDashboardLine, RiUserLine } from 'react-icons/ri';
import './Header.css';
import logo from '../../assets/logo_campuslens_colored.svg';
import { useAuth } from '../../services/auth.service';

const Header = () => {
  const { currentUser, logout } = useAuth();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    console.log(currentUser);
  }, [currentUser]);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const handleNavigation = (path) => {
    window.scrollTo(0, 0);
    navigate(path);
    if (isMenuOpen) {
      toggleMenu();
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    document.body.style.overflow = isMenuOpen ? 'unset' : 'hidden';
  };

  const isLoggedIn = () => {
    return currentUser && currentUser.access_token;
  }

  return (
    <header className="header">
      <nav className="nav-container">
        <div className="logo-container">
          <div className="logo-link" onClick={() => handleNavigation('/')}>
            <img src={logo} alt="Logo" className="logo-image" />
          </div>
        </div>
        {isMobile ? (
          <button className="menu-btn" onClick={toggleMenu} aria-label="Toggle menu">
            {isMenuOpen ? <RiCloseLine /> : <RiMenuLine />}
          </button>
        ) : (
          <div className="desktop-nav">
            <div className="nav-link" onClick={() => handleNavigation('/')}>Home</div>
            <div className="nav-link" onClick={() => handleNavigation('/about')}>About</div>
            <div className="nav-link" onClick={() => handleNavigation('/contact')}>Contact</div>
            <div className="nav-link dashboard-link" onClick={() => handleNavigation('/dashboard')}>
              <RiDashboardLine className="nav-icon" />
              Dashboard
            </div>
            {!isLoggedIn() && <div className="nav-link signin-link" onClick={() => handleNavigation('/signin')}>
              <RiUserLine className="nav-icon" />
              Sign In
            </div>}
            {isLoggedIn() && <div className="nav-link signin-link" onClick={() => logout()}>
              <RiUserLine className="nav-icon" />
              Logout
            </div>}
          </div>
        )}
        <div className={`options-menu ${isMenuOpen ? 'active' : ''}`}>
          <div className="menu-content">
            <div className="menu-item" onClick={() => handleNavigation('/')}>Home</div>
            <div className="menu-item" onClick={() => handleNavigation('/about')}>About</div>
            <div className="menu-item" onClick={() => handleNavigation('/contact')}>Contact</div>
            <div className="menu-item" onClick={() => handleNavigation('/dashboard')}>
              <RiDashboardLine className="nav-icon" />
              Dashboard
            </div>
          </div>
          {(currentUser && currentUser.accessToken) ? <div>Nothing</div> :
            <div className="menu-signin">
              <div className="menu-item signin-item" onClick={() => handleNavigation('/signin')}>
                <RiUserLine className="nav-icon" />
                Sign In
              </div>
            </div>}
        </div>
      </nav>
    </header>
  );
};

export default Header;
