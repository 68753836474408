import React, { useState, useEffect } from 'react';
import './Dashboard.css';
import { getAllDetections } from '../../services/detection.service';

const Dashboard = () => {
  const [selectedFacility, setSelectedFacility] = useState(null);
  const [analyticsData, setAnalyticsData] = useState(null);
  const [filter, setFilter] = useState('All');
  const [hoveredBar, setHoveredBar] = useState(null);
  const [isMobile, setIsMobile] = useState(false);

  const [facilities, setFacilities] = useState(() => {
    return [
      {
        id: 1,
        name: 'Rush Rhees Library',
        current: 120,
        capacity: 200,
        status: 'HIGH',
        type: 'library'
      },
      {
        id: 2,
        name: 'iZone',
        current: 45,
        capacity: 150,
        status: 'MODERATE',
        type: 'library'
      },
      {
        id: 3,
        name: 'Arts & Music Library',
        current: 25,
        capacity: 100,
        status: 'LOW',
        type: 'library'
      },
      {
        id: 4,
        name: 'Carlson Library',
        current: 85,
        capacity: 180,
        status: 'HIGH',
        type: 'library'
      },
      {
        id: 5,
        name: 'Douglass Dining Center',
        current: 120,
        capacity: 200,
        status: 'HIGH',
        type: 'dining'
      },
      {
        id: 6,
        name: 'Danforth Dining Center',
        current: 65,
        capacity: 150,
        status: 'MODERATE',
        type: 'dining'
      },
      {
        id: 7,
        name: 'Goergen Athletic Center',
        current: 25,
        capacity: 100,
        status: 'LOW',
        type: 'gym'
      }
    ]
  });

  const fetchData = async () => {
    console.log(facilities);
    const res = await getAllDetections();
    console.log(res);
    console.log(res[0]);

    // Create a *new* array with the updated value
    const newFacilities = facilities.map((facility, index) => {
      if (index === 6) { // Update only the first facility
        let status = "MODERATE";
        if (res[0].detection_count < 20) {
          status = "LOW";
        }else if(res[0].detection_count < 70) {
          status = "MODERATE";
        }else{
          status = "HIGH";
        }
        return {
          ...facility,
          current: res[0].detection_count,
          status: status,
        };
      }
      return facility; // Keep other facilities unchanged
    });

    setFacilities(newFacilities); // No need for the callback here
    console.log("new facilities:");
    console.log(newFacilities); // Log newFacilities, not facilities
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);

    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    if (selectedFacility) {
      fetchAnalyticsData(selectedFacility.id);
    }
  }, [selectedFacility]);

  const fetchAnalyticsData = async (facilityId) => {
    const mockData = {
      todayOccupancy: generateMockDayData(),
      weeklyAverages: {
        Monday: 145,
        Tuesday: 160,
        Wednesday: 175,
        Thursday: 155,
        Friday: 140,
        Saturday: 90,
        Sunday: 85
      },
      peakHours: {
        morning: '10:00 AM - 12:00 PM',
        afternoon: '2:00 PM - 4:00 PM',
        evening: '7:00 PM - 9:00 PM'
      },
      occupancyTrends: {
        lastHour: 65,
        lastDay: 150,
        lastWeek: 1200,
        weeklyGrowth: 5.2
      }
    };
    setAnalyticsData(mockData);
  };

  const generateMockDayData = () => {
    const data = [];
    const now = new Date();
    now.setHours(8, 0, 0, 0);

    for (let i = 0; i < 72; i++) {
      data.push({
        timestamp: new Date(now.getTime() + i * 5 * 60000).toISOString(),
        count: Math.floor(Math.random() * 50) + 20
      });
    }
    return data;
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'HIGH': return '#ff4d4d';
      case 'MODERATE': return '#ffd700';
      case 'LOW': return '#4CAF50';
      default: return '#gray';
    }
  };

  const handleCardClick = (facility) => {
    setSelectedFacility(facility);
    if (isMobile) {
      document.body.style.overflow = 'hidden';
    }
  };

  const handleCloseModal = () => {
    setSelectedFacility(null);
    document.body.style.overflow = 'unset';
  };

  const [filteredFacilities, setFilteredFacilities] = useState([]);

  useEffect(() => {
    if (!facilities) return;
    setFilteredFacilities(filter === 'All'
      ? facilities
      : facilities.filter(f => f.type === filter.toLowerCase()));
  }, [facilities]);

  const renderOccupancyChart = (data) => {
    if (!data?.todayOccupancy) return null;

    const maxCount = Math.max(...data.todayOccupancy.map(d => d.count));

    const getTimeRange = (timestamp) => {
      const date = new Date(timestamp);
      const hour = date.getHours();
      const minute = date.getMinutes();
      return `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
    };

    return (
      <div className="chart-wrapper">
        <div className="chart-axis-y">
          {[...Array(5)].map((_, i) => (
            <span key={i}>{Math.round((maxCount / 4) * (4 - i))}</span>
          ))}
        </div>
        <div className="chart-content">
          <div className="simple-chart">
            {data.todayOccupancy.map((point, index) => (
              <div
                key={index}
                className={`chart-bar ${hoveredBar === index ? 'active' : ''}`}
                style={{
                  height: `${(point.count / maxCount) * 100}%`,
                  backgroundColor: `rgba(76, 175, 80, ${(point.count / maxCount) * 0.8 + 0.2})`
                }}
                onMouseEnter={() => setHoveredBar(index)}
                onMouseLeave={() => setHoveredBar(null)}
              >
                <div className={`chart-tooltip ${hoveredBar === index ? 'visible' : ''}`}>
                  <strong>{point.count} people</strong>
                  <span>{getTimeRange(point.timestamp)}</span>
                </div>
              </div>
            ))}
          </div>
          <div className="chart-grid">
            {[...Array(5)].map((_, i) => (
              <div key={i} className="chart-grid-line" />
            ))}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="dashboard">
      <div className="dashboard-header">
        <h1>Campus Facilities Status</h1>
        <div className="filter-buttons">
          <button
            className={`filter-btn ${filter === 'All' ? 'active' : ''}`}
            onClick={() => setFilter('All')}
          >
            All
          </button>
          <button
            className={`filter-btn ${filter === 'library' ? 'active' : ''}`}
            onClick={() => setFilter('library')}
          >
            Libraries
          </button>
          <button
            className={`filter-btn ${filter === 'dining' ? 'active' : ''}`}
            onClick={() => setFilter('dining')}
          >
            Dining Halls
          </button>
          <button
            className={`filter-btn ${filter === 'gym' ? 'active' : ''}`}
            onClick={() => setFilter('gym')}
          >
            Gym
          </button>
        </div>
      </div>
      <div className="facilities-grid">
        {filteredFacilities && filteredFacilities.constructor == Array && filteredFacilities.map(facility => (
          <div
            key={facility.id}
            className="facility-card"
            onClick={() => handleCardClick(facility)}
          >
            <h2>{facility.name}</h2>
            <div className="occupancy-bar">
              <div
                className="occupancy-fill"
                style={{
                  width: `${(facility.current / facility.capacity) * 100}%`,
                  backgroundColor: getStatusColor(facility.status)
                }}
              ></div>
            </div>
            <div className="facility-stats">
              <span>{facility.current} / {facility.capacity}</span>
              <span className={`status-badge ${facility.status.toLowerCase()}`}>
                {facility.status}
              </span>
            </div>
          </div>
        ))}
      </div>
      {selectedFacility && analyticsData && (
        <div className="analytics-modal" onClick={handleCloseModal}>
          <div className="modal-content" onClick={e => e.stopPropagation()}>
            <h2>{selectedFacility.name} Analytics</h2>
            <div className="occupancy-chart">
              <h3>Today's Occupancy Trend</h3>
              {renderOccupancyChart(analyticsData)}
            </div>
            <div className="analytics-grid">
              <div className="analytics-card">
                <h3>Current Status</h3>
                <p className="analytics-number">{selectedFacility.current}</p>
                <div className="analytics-details">
                  <p>Capacity: {selectedFacility.capacity}</p>
                  <p>Utilization: {Math.round((selectedFacility.current / selectedFacility.capacity) * 100)}%</p>
                </div>
              </div>
              <div className="analytics-card">
                <h3>Today's Traffic</h3>
                <div className="analytics-details">
                  <p>Peak Time: {analyticsData.peakHours.afternoon}</p>
                  <p>Quiet Time: {analyticsData.peakHours.morning}</p>
                  <p>Average Traffic: {analyticsData.occupancyTrends.lastDay} visitors</p>
                </div>
              </div>
              <div className="analytics-card">
                <h3>Weekly Overview</h3>
                <div className="analytics-details">
                  <p>Busiest Day: Wednesday ({analyticsData.weeklyAverages.Wednesday})</p>
                  <p>Quietest Day: Sunday ({analyticsData.weeklyAverages.Sunday})</p>
                  <p>Weekly Growth: {analyticsData.occupancyTrends.weeklyGrowth}%</p>
                </div>
              </div>
              <div className="analytics-card">
                <h3>Recommendations</h3>
                <div className="analytics-details recommendation-list">
                  <p>• Best time to visit: Before {analyticsData.peakHours.morning}</p>
                  <p>• Avoid peak hours: {analyticsData.peakHours.afternoon}</p>
                  <p>• Current wait time: {selectedFacility.current > selectedFacility.capacity * 0.8 ? '15-20 mins' : '5-10 mins'}</p>
                </div>
              </div>
            </div>
            <button className="close-modal" onClick={handleCloseModal}>
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
