import React from 'react';
import { RiCheckLine } from 'react-icons/ri';
import integrationImage from '../../assets/integration.png';
import urgym from '../../assets/ur_gym.png';
import './Integration.css';

const Integration = () => {
  const steps = [
    {
      title: "Quick Setup",
      description: "Installation and configuration in less than a day",
      step: 1
    },
    {
      title: "Privacy First",
      description: "No personal data collection or storage",
      step: 2
    },
    {
      title: "Real-Time Monitoring",
      description: "24/7 system health monitoring and support",
      step: 3
    }
  ];

  return (
    <section className="integration">
      <div className="integration-container">
        <div className="integration-content">
          <h2 className="integration-title">
            Seamless Integration
            <br />
            <span className="gradient-text">With Your Campus</span>
          </h2>
          <p className="integration-description">
            Easy to deploy, maintain, and scale across all your facilities
          </p>
          <div className="steps-container">
            {steps.map((step, index) => (
              <div key={index} className="step-card">
                <div className="step-header">
                  <div className="step-number">
                    <RiCheckLine className="step-check" />
                    <span>Step {step.step}</span>
                  </div>
                </div>
                <h3 className="step-title">{step.title}</h3>
                <p className="step-description">{step.description}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="integration-visual">
          <div className="integration-image-container">
            <img
              src={urgym}
              alt="CampusLens Integration"
              className="integration-image"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Integration;