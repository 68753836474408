import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import React from 'react';
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
const API_URL = "https://campuslens.xyz/api/auth";
axios.defaults.withCredentials = true;

const initialState = {
    currentUser: null,
    setCurrentUser: (user) => { },
};

const AuthContext = createContext(initialState);

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(
        () => {
            const storedUser = localStorage.getItem("user");
            if (!storedUser) return null;
            const user = JSON.parse(storedUser);
            if(!user) return null;
            const token = user.access_token;
            if (!token) return null;
            const decodedJwt = jwtDecode(token);
            //console.log(decodedJwt.exp*1000 + " " + Date.now());
            if (decodedJwt.exp * 1000 < Date.now()) {
                localStorage.removeItem("user");
                return null;
            }
            return user;
        }
    );

    useEffect(()=>{
        localStorage.setItem("user", JSON.stringify(currentUser));
    }, [currentUser]);

    const navigate = useNavigate();

    const login = async (userDetail) => {
        try {
            const response = await axios.post(API_URL + "/login", userDetail);
            //console.log(response.data);
            if (response.data.access_token) {
                //console.log("Got user access token");
                setCurrentUser(response.data);
            }
            return response.data;
        } catch (error) {
            console.error("Login failed:", error);
        }
    };

    const signup = async (userDetail) => {
        try {
            console.log("Sending user signup request");
            const response = await axios.post(API_URL + "/register", userDetail);
            if (response.data.message == "User registered successfully!") {
                login(userDetail.username, userDetail.password);
            }

            return response.data;
        } catch (error) {
            //receive ErrorDTO
            console.log(error);

            const errors = error.response.data.errors;
            throw errors;
        }
    }

    const logout = async () => {
        try {
            setCurrentUser(null);
            localStorage.removeItem("user");
            navigate("/");
            return true;
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <AuthContext.Provider value={{ currentUser, login, logout, signup, setCurrentUser }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error("useAuth must be used within an AuthProvider");
    }
    return context;
};
