import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-content">
          <div className="footer-section">
            <h3>Product</h3>
            <ul>
              <li><a href="/features">Features</a></li>
              <li><a href="/pricing">Pricing</a></li>
              <li><a href="/security">Security</a></li>
            </ul>
          </div>
          <div className="footer-section">
            <h3>Legal</h3>
            <ul>
              <li><a href="/privacy">Privacy Policy</a></li>
              <li><a href="/terms">Terms of Service</a></li>
              <li><a href="/refund">Refund Policy</a></li>
              <li><a href="/compliance">Compliance</a></li>
            </ul>
          </div>
          <div className="footer-section">
            <h3>Support</h3>
            <ul>
              <li><a href="/documentation">Documentation</a></li>
              <li><a href="/contact">Contact</a></li>
            </ul>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="footer-logo">
            <span className="logo-text">Campus<span className="gradient-text">Lens</span></span>
          </div>
          <div className="footer-info">
            <p>© 2025 CampusLens. All rights reserved.</p>
            <div className="footer-links">
              <a href="mailto:campuslens@u.rochester.edu">campuslens@u.rochester.edu</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;