import React from 'react';
import './About.css';
import UR from '../../assets/Urochester.png';
import Camera from '../../assets/camera2.png';

const About = () => {
  return (
    <div className="about">
      <section className="about-hero">
        <div className="about-container">
          <div className="about-content">
            <h1 className="about-title">
              Revolutionizing
              <br />
              <span className="gradient-text">Campus Management</span>
            </h1>
            <p className="about-description">
              Founded by a team of passionate engineers and campus management experts, CampusLens
              is dedicated to transforming how universities manage their facilities through
              cutting-edge AI technology.
            </p>
          </div>
          <div className="about-image-container">
            <img src={UR} alt="University of Rochester" className="about-image" />
          </div>
        </div>
      </section>

      <section className="mission-section">
        <div className="about-container">
          <div className="mission-content">
            <h2>Our Mission</h2>
            <p>
              To provide educational institutions with affordable, accurate, and
              privacy-focused occupancy management solutions that enhance the campus
              experience for students and staff alike.
            </p>
          </div>
        </div>
      </section>

      <section className="values-section">
        <div className="about-container">
          <h2>Our Core Values</h2>
          <div className="values-grid">
            <div className="value-card">
              <h3>Innovation</h3>
              <p>Pushing boundaries in AI and computer vision technology</p>
            </div>
            <div className="value-card">
              <h3>Accessibility</h3>
              <p>Making advanced technology affordable for all institutions</p>
            </div>
            <div className="value-card">
              <h3>Privacy</h3>
              <p>Ensuring data protection and user privacy at all times</p>
            </div>
            <div className="value-card">
              <h3>Reliability</h3>
              <p>Delivering consistent and accurate performance</p>
            </div>
          </div>
        </div>
      </section>

      <section className="tech-section">
        <div className="about-container">
          <div className="tech-content">
            <div className="tech-text">
              <h2>Our Technology</h2>
              <p>
                Using state-of-the-art computer vision and machine learning algorithms,
                CampusLens provides real-time occupancy data with industry-leading
                accuracy while maintaining strict privacy standards.
              </p>
              <ul className="tech-features">
                <li>Advanced AI-powered detection</li>
                <li>Privacy-first architecture</li>
                <li>Real-time processing</li>
                <li>Scalable infrastructure</li>
              </ul>
            </div>
            <div className="tech-image-container">
              <img src={Camera} alt="AI Camera Technology" className="tech-image" />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;